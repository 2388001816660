import { experimentClient, isExperimentLoaded } from "analytics";
import { useEffect, useMemo, useState } from "react";

export const useExperiment = (name: string, fallback = "control") => {
  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    const loadExperiments = async () => {
      await isExperimentLoaded;
      setInitiated(true);
    };

    if (!initiated) {
      loadExperiments();
    }
  }, [initiated]);

  const variant = useMemo(() => {
    if (!process.env.NEXT_PUBLIC_AMPLITUDE_EXPERIMENT_DEPLOYMENT_KEY) {
      return fallback;
    }

    if (initiated && experimentClient) {
      return experimentClient.variant(name, fallback).value;
    }
  }, [initiated, name]);

  return { variant, initiated };
};
